import React from "react"
import Image from "gatsby-image"

const NonStretchedImage = ({ width, alt, ...props }) => {
  let normalizedProps = props
  if (width) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: width,
        marginLeft: props.noMargin ? "" : "auto",
        marginRight: props.noMargin ? "" : "auto",
      },
    }
  }

  return <Image {...normalizedProps} alt={alt} />
}

export default NonStretchedImage
