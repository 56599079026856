import React, { useEffect, useState, useRef } from "react"
import { ChatSegment } from "../components/ChatSegment"

export const ChatSection = props => {
  let dividedChat = []
  let chats = []
  const initialDelay = 4000

  props.data.Chat.bubbles.forEach(bubble => {
    dividedChat.push(bubble)
    if (bubble.participant === "User") {
      chats.push(dividedChat)
      dividedChat = []
    }
  })

  const [chatSegments, addSegment] = useState([chats[0]])
  const [chatHeight, setHeight] = useState(0)

  const addNewSegment = index => {
    let newChatSegments = []
    chats.forEach((chat, i) => {
      if (i <= index) {
        newChatSegments.push(chat)
      }
    })
    addSegment(newChatSegments)
  }

  const scrollElement = useRef()

  useEffect(() => {
    setTimeout(() => {
      setHeight(1)
    }, 3500)
    setTimeout(() => {
      props.beginChat(true)
    }, initialDelay)
  }, [])

  return (
    <div
      ref={scrollElement}
      className={`relative flex-nowrap flex-col transition-all opacity-${chatHeight} duration-1000 h-full w-full p-8 pb-56 overflow-scroll md:bg-gray-50 border-gray-300 md:rounded-3xl md:shadow-2xl leading-tight`}
    >
      {props.chatBegins &&
        chatSegments.map((segment, index) => {
          return (
            <ChatSegment
              key={`key-${index}`}
              scrollElement={scrollElement}
              endChat={props.endChat}
              chatContent={segment}
              index={index}
              addSegment={addNewSegment}
              lastSegment={index === chats.length - 1}
            />
          )
        })}
    </div>
  )
}
