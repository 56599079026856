import React, { useEffect, useState } from "react"
import { AiOutlineSend } from "react-icons/ai"

export const Bubble = props => {
  const [pressed, press] = useState(false)
  const [translate, setTranslate] = useState(false)
  const { bubble, index } = props

  useEffect(() => {
    setTimeout(() => {
      setTranslate(true)
    }, 100)
  }, [])

  if (
    (bubble.participant !== "User" && translate) ||
    (bubble.participant === "User" && pressed)
  ) {
    return (
      <div
        className={`flex transition-all transform mt-0 ${
          bubble.participant === "User"
            ? "justify-end duration-500"
            : "justify-start duration-400"
        }`}
      >
        <div
          key={index}
          className={`flex my-1 py-2 px-4 shadow-lg rounded-md max-w-3/4 items-center ${
            bubble.participant === "User" ? "bg-yellow-500" : "bg-white"
          }`}
        >
          {!bubble.media ? (
            <h2>{bubble.content}</h2>
          ) : (
            <img src={bubble.media.file.url}></img>
          )}
        </div>
      </div>
    )
  } else if (bubble.participant !== "User" && !translate) {
    return (
      <div
        className={`flex transform opacity-0 mt-4 scale-90 ${
          bubble.participant === "User" ? "justify-end" : "justify-start"
        }`}
      >
        <div
          key={index}
          className={`flex my-1 py-2 px-4 shadow-lg rounded-md max-w-3/4 items-center ${
            bubble.participant === "User" ? "bg-yellow-500" : "bg-white"
          }`}
        >
          {!bubble.media ? (
            <h2>{bubble.content}</h2>
          ) : (
            <img src={bubble.media.file.url}></img>
          )}
        </div>
      </div>
    )
  } else if (bubble.participant === "User" && !pressed) {
    return (
      <div
        className={`fixed duration-500 ease-in-out md:static mt-16 flex justify-center inset-x-8 bottom-8`}
      >
        <div
          onClick={() => {
            if (bubble.participant === "User" && !pressed) {
              press(true)
              if (props.lastSegment) {
                props.endChat(true)
              } else {
                props.addSegment(props.segmentIndex + 1)
              }
            }
          }}
          key={index}
          className="flex shadow-lg rounded-xl w-2/3 items-center bg-yellow-500 hover:bg-yellow-300 cursor-pointer overflow-hidden"
        >
          {/* Text content */}
          <h2 className="p-4 font-bold max-w-3/4">{bubble.content}</h2>
          {/* Send arrow */}
          <div className="ml-auto flex items-center justify-center w-bubble bg-yellow-400 h-full">
            <AiOutlineSend className="" />
          </div>
        </div>
      </div>
    )
  }
}
