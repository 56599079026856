import React, { useEffect, useState } from "react"
import { Chat } from "../components/Chat"

export const ChatSegment = props => {
  const [chatContent, addToChat] = useState([])

  const baseDelay = 700

  const addDelay = (bubble, index, prevDelay) => {
    const prevContent = index === 0 ? null : props.chatContent[index - 1]
    let addedDelay
    if (bubble.participant === "User") {
      addedDelay = 800 + baseDelay
    } else if (prevContent && prevContent.media) {
      addedDelay = 3000
      console.log("GIF")
    } else if (!prevContent) {
      addedDelay = baseDelay
    } else {
      addedDelay =
        prevContent && prevContent.content.length * 50 > 400
          ? prevContent.content.length * 35 + baseDelay
          : baseDelay
    }
    const delay = index === 0 && props.index === 0 ? 0 : prevDelay + addedDelay

    return { delay, addedDelay }
  }

  useEffect(() => {
    let prevDelay = 0

    props.chatContent.forEach((bubble, index) => {
      const delays = addDelay(bubble, index, prevDelay)

      prevDelay += delays.addedDelay

      setTimeout(() => {
        let newChat = []
        props.chatContent.forEach((bubble, i) => {
          if (i <= index) {
            newChat.push(bubble)
          }
        })
        if (props.scrollElement.current) {
          props.scrollElement.current.scrollTop +=
            props.scrollElement.current.scrollHeight
        }
        addToChat(newChat)
      }, delays.delay)
    })
  }, [])

  return (
    <div className={`flex flex-col`}>
      <Chat
        endChat={props.endChat}
        chatContent={chatContent}
        addSegment={props.addSegment}
        index={props.index}
        lastSegment={props.lastSegment}
      />
    </div>
  )
}
