import React from "react"
import brushColor from "../images/brush-color.svg"

const ImageTitle = ({ title, titleImage, width, textClasses }) => {
  return (
    <div className="relative flex items-center justify-center -mt-4 md:-mt-6">
      <span className={`absolute z-10 font-bold text-black ${textClasses}`}>
        {titleImage ? (
          <img src={titleImage} alt={title} className="-mt-2 sm:w-64" />
        ) : (
          title
        )}
      </span>

      <img
        src={brushColor}
        alt=""
        className="block mx-auto"
        style={{ width: `${width}px` }}
      />
    </div>
  )
}

export default ImageTitle
