import React from "react"
import ReactModal from "react-modal"
import iconOpen from "../images/icon-open.svg"

export const Modal = props => {
  ReactModal.setAppElement(`#___gatsby`)
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.handleClose}
      closeTimeoutMS={300}
      className="absolute max-h-full p-8 overflow-y-scroll text-white transform bg-black outline-none h-3/4 md:h-auto rounded-3xl md:max-w-xl"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-30 flex items-end md:items-center justify-center"
      htmlOpenClassName="overflow-hidden"
      bodyOpenClassName="overflow-hidden"
    >
      {props.children}
      <button
        aria-label="Sulje"
        onClick={props.handleClose}
        className="absolute p-2 rounded-full hover:bg-red-500 focus:outline-none focus:bg-red-500 top-4 right-4 group"
      >
        <img
          src={iconOpen}
          alt=""
          className="block transition-all transform rotate-45 group-hover:-rotate-45 group-focus:-rotate-45"
        />
      </button>
    </ReactModal>
  )
}
