import React, { useEffect, useState } from "react"
import { Bubble } from "./Bubble.js"

export const Chat = props => {
  useEffect(() => {
    // console.log(props)
  })
  return (
    <>
      {props.chatContent.length > 0 &&
        props.chatContent.map((bubble, index) => (
          <Bubble
            key={bubble.content ? bubble.content : bubble.media.file.url}
            press={props.press}
            pressed={props.pressed}
            bubble={bubble}
            index={index}
            addSegment={props.addSegment}
            segmentIndex={props.index}
            endChat={props.endChat}
            lastSegment={props.lastSegment}
            scrollElement={props.scrollElement}
            setBubble={props.setBubble}
          />
        ))}
    </>
  )
}
