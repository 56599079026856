import React, { useEffect, useState } from "react"
import logo from "../images/logo-horisontal.svg"

const JumpingBall = props => {
  const [translateY, setTranslateY] = useState(1)
  const jump = () => {
    setTranslateY(-1 * translateY)
  }
  useEffect(() => {
    setInterval(() => {
      jump()
    }, 700)
  }, [translateY])
  return (
    <div
      className={`w-2 h-2 bg-gray-500 rounded-full transform transition-transform translate-y-${
        translateY * 2
      } duration-700 ease-in-out delay-${props.delay}`}
    ></div>
  )
}

const TypingAnimation = () => {
  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 100)
    setTimeout(() => {
      setOpacity(0)
    }, 2500)
  }, [])
  return (
    <div
      className={`flex flex-row w-full h-full items-center mx-auto justify-evenly transition-opacity opacity-${opacity} duration-1000 linear`}
    >
      <img src={logo} className="w-full md:w-1/3" />
    </div>
  )
}

export default TypingAnimation
