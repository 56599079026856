import React, { useEffect, useState } from "react"
import Layout from "../layouts/default"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import ImageTitle from "../components/ImageTitle"
import NonStretchedImage from "../components/NonStretchedImage"
import brushDark from "../images/brush-dark.svg"
import symbol from "../images/symbol-paapaiva.svg"
import logo from "../images/logo-horisontal.svg"
import iconOpen from "../images/icon-open.svg"
import logoType from "../images/logo-type.svg"
import LogoPattern from "../images/logo-bg.png"
import { Modal } from "../components/Modal"
import { ChatSection } from "../components/ChatSection"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import TypingAnimation from "../components/TypingAnimation"

const pageData = {
  title: "Pääpäivä",
}

const GtagEvent = ({ event = "event", name = "click", data = null }) => {
  if (typeof window !== "undefined" && typeof gtag === "function") {
    window.gtag(event, name, data)
  }
}

const IndexPage = ({ data }) => {
  const [manifestOpen, setManifestOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  })

  const [chatBegins, beginChat] = useState(false)

  const [renderChat, setRenderChat] = useState(true)

  const [chatHasEnded, endChat] = useState(false)

  useEffect(() => {
    console.log(data)
    if (chatHasEnded) {
      setTimeout(() => {
        setRenderChat(false)
      }, 1100)
    }
  }, [chatHasEnded, chatBegins])

  return (
    <Layout pageData={pageData}>
      {renderChat && (
        <section
          style={{
            backgroundImage: `url(${LogoPattern})`,
          }}
          className={`transform transition duration-1000 ease-in-out absolute z-50 top-0 w-full ${
            chatHasEnded && "-translate-y-full"
          } h-full flex flex-col items-center md:py-16 mx-auto space-y-2 text-left`}
        >
          {!chatBegins && (
            <div className="w-2/3 md:w-full h-full absolute top-0 z-20">
              <TypingAnimation />
            </div>
          )}
          <div className="w-full md:max-w-sm h-full relative">
            {chatBegins && (
              <div
                className="absolute top-4 right-8 cursor-pointer z-50 flex flex-row justify-end"
                onClick={() => {
                  console.log("click")
                  endChat(true)
                }}
              >
                <h3 className="font-bold text-gray-400">Ohita chat</h3>
              </div>
            )}
            {
              <div className="flex flex-col items-center justify-center w-full h-full">
                <ChatSection
                  data={data}
                  endChat={endChat}
                  beginChat={beginChat}
                  chatBegins={chatBegins}
                />
              </div>
            }
          </div>
        </section>
      )}

      {chatHasEnded && (
        <>
          <header className="px-4 my-12 text-center">
            <img
              src={symbol}
              alt="Pääpäivä.fi"
              className="block mx-auto mb-8"
            />
            <h1 className="block">
              <div className="relative flex items-center justify-center">
                <span className="absolute z-10 text-xl font-bold text-white uppercase md:text-2xl">
                  Tänään on
                </span>
                <img
                  src={brushDark}
                  alt=""
                  className="block mx-auto"
                  style={{ width: "100%", maxWidth: "250px" }}
                />
              </div>
              <ImageTitle
                titleImage={logoType}
                title="Pääpäivä"
                width={510}
                textClasses="text-5xl md:text-6xl"
              />
            </h1>
            <p className="max-w-md mx-auto mt-8 text-xl leading-snug text-coolGray-600 md:text-2xl">
              Se päivä, kun ollaan täysillä yhdessä – vaikka sitten etänä,
              hittolainen
            </p>
            <p className="mt-8 text-xl font-bold">
              <span role="img" aria-label="Katso alaspäin">
                👇
              </span>{" "}
              Näin olet mukana{" "}
              <span role="img" aria-label="">
                👇
              </span>
            </p>
          </header>
          {data.CardBlocks.edges.map(({ node }) => {
            return (
              <section
                style={{ backgroundColor: node.backgroundColor }}
                key={node.title}
              >
                <div className="container max-w-6xl py-8 md:py-16">
                  <h2 className="mb-1 text-3xl font-bold text-white sm:mb-5 sm:text-4xl sm:leading-3">
                    {node.title}
                  </h2>
                  <p className="mb-4 text-lg leading-tight text-white sm:mb-8">
                    {node.description}
                  </p>
                  <div className="grid grid-flow-col gap-4 py-2 -mx-8 overflow-x-scroll md:overflow-x-auto custom-grid-fix">
                    {node.cards.map(item => (
                      <button
                        className="overflow-hidden text-white bg-gray-900 cursor-pointer focus:outline-none focus:ring-4 hover:ring-4 focus:ring-black rounded-xl group hover:ring-black hover:ring-opacity-25"
                        key={item.title}
                        onClick={() => {
                          setModalOpen(!modalOpen)
                          setModalContent({
                            title: item.modalTitle || "[Otsikko puuttuu]",
                            content:
                              item.modalText?.childMarkdownRemark.html ||
                              "[Teksti puuttuu]",
                          })
                          GtagEvent({
                            name: "modal open",
                            data: {
                              title: `${item.title}`,
                            },
                          })
                        }}
                      >
                        <h3
                          className={`p-5 text-xl lg:text-2xl font-bold whitespace-nowrap text-center ${
                            item.lightText ? "text-white" : "text-gray-900"
                          }`}
                          style={{ backgroundColor: item.titleBackgroundColor }}
                        >
                          {item.title}
                        </h3>
                        <div className="relative">
                          {/* Button */}
                          <div className="absolute z-20 flex items-center justify-center text-2xl text-white transition-all transform bg-gray-800 bg-opacity-50 rounded-full group-hover:transition-none w-14 h-14 bottom-4 right-4 group-hover:bg-opacity-100">
                            <img src={iconOpen} alt="" />
                          </div>
                          {/* Image */}
                          <div className="absolute bottom-0 z-10 w-full">
                            <NonStretchedImage
                              fluid={item.image.fluid}
                              style={{
                                maxWidth: "250px",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                          </div>
                          {/* Background */}
                          <div
                            className="overflow-hidden"
                            style={{ height: "230px" }}
                          >
                            <Image
                              fluid={item.backgroundImage.fluid}
                              className="h-full"
                            />
                          </div>
                        </div>
                        <p className="flex items-center h-32 p-6 text-base bg-gray-900">
                          {item.description}
                        </p>
                      </button>
                    ))}
                  </div>
                </div>
              </section>
            )
          })}
          {/* Manifest */}
          <section>
            <div className="container max-w-2xl pt-12 pb-12 md:pt-24">
              <h2 className="mb-6 text-3xl font-bold md:text-5xl">
                {data.Manifest.title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.Manifest.text.childMarkdownRemark.html,
                }}
                className={`space-y-3 overflow-hidden text-lg leading-snug  md:space-y-6 md:text-md formatted-text ${
                  manifestOpen ? "" : "max-h-52 md:pb-12"
                }`}
              ></div>
              {!manifestOpen && (
                <div className="relative flex items-end justify-center w-full h-48 -mt-48 bg-gradient-to-t from-white">
                  <button
                    onClick={() => {
                      setManifestOpen(!manifestOpen)
                      GtagEvent({
                        name: "read manifest",
                        data: {
                          title: "Opened manifest",
                        },
                      })
                    }}
                    className="inline-flex items-center px-6 py-4 text-lg font-bold leading-4 text-black bg-white border-2 border-black rounded-md shadow-lg hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  >
                    Näytä lisää +
                  </button>
                </div>
              )}
            </div>
          </section>
          {/* Main sponsors */}
          <section>
            <div className="container max-w-3xl py-12 md:py-24">
              <h2 className="mb-8">
                <ImageTitle
                  title="Pääkumppanit"
                  width={300}
                  textClasses="text-3xl"
                />
              </h2>
              <ul className="flex justify-center mx-auto">
                {data.BigSponsors.companies.map(company => (
                  <li className="text-center" key={company.name}>
                    <OutboundLink
                      href={company.link}
                      className="inline-block p-2 rounded-lg sm:block sm:p-6 group hover:bg-yellow-100"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NonStretchedImage fixed={company.logo.fixed} alt="" />
                      <p className="text-sm text-gray-800 group-hover:text-gray-900 md:text-base">
                        {company.name}
                      </p>
                    </OutboundLink>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section
            style={{
              backgroundImage: `url(${LogoPattern})`,
              backgroundRepeat: "repeat",
            }}
            className="py-16"
          >
            <h2 className="text-5xl font-bold text-center">
              <span role="img" className="block mb-4" aria-label="Peace">
                ✌️
              </span>
              Yhteyttä
            </h2>
          </section>
          <section className="container max-w-3xl py-12 md:py-24">
            <h2 className="mb-4 text-xl font-bold text-center sm:mb-8">
              Messissä myös
            </h2>
            <ul className="flex flex-wrap justify-center mx-auto">
              {data.RegularSponsors.companies.map(company => (
                <li
                  className="inline-block px-2 py-6 text-center sm:block sm:px-6"
                  key={company.name}
                >
                  <div className="flex items-center flex-col justify-center h-full w-full">
                    <img
                      style={{ width: "80px" }}
                      src={company.logo.fixed.src}
                      alt=""
                    />
                  </div>
                </li>
              ))}
            </ul>
          </section>
          {/* Footer */}
          <footer className="py-16 text-center md:py-24 custom-gradient">
            <img src={logo} alt="" className="block mx-auto mb-3" />
            <p>
              Copyright &copy; 2021{" "}
              <OutboundLink
                href="https://lifeclass.fi"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Lifeclass
              </OutboundLink>
            </p>
          </footer>
          <Modal isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
            <h2 className="pr-10 mb-6 text-3xl font-bold">
              {modalContent.title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: modalContent.content,
              }}
              className="space-y-7 formatted-text"
            ></div>
          </Modal>
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    Manifest: contentfulManifest {
      title
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    BigSponsors: contentfulBigSponsors {
      companies {
        name: company
        link
        logo {
          fixed(height: 48, quality: 75) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
    RegularSponsors: contentfulRegularSponsors {
      companies {
        name: company
        link
        logo {
          fixed(width: 80, quality: 75) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
    Chat: contentfulChatConversation {
      bubbles {
        content
        participant
        media {
          file {
            url
          }
        }
      }
    }
    CardBlocks: allContentfulCardBlock(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title
          description
          backgroundColor
          cards {
            title
            lightText
            titleBackgroundColor
            description
            backgroundImage {
              fluid(quality: 75) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            image {
              fluid(maxWidth: 250, quality: 75) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                details {
                  image {
                    width
                  }
                }
              }
            }
            modalTitle
            modalText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
